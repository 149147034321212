import * as React from "react"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ContactUs from "../components/contactus";


const linkStyle = {
    textDecoration: "none",
    color: "grey",
    fontWeight: 800,
}

const hrStyle = {
    margin: "50px auto"
}

const leftTdStyle = {
    padding: "10px",
}

const priceLeftTdStyle = {
    padding: "10px",
}


const CloudPBXPage = () => (
    <Layout>
        <Seo title="CloudPBX"/>

        <div align="center">
            <hr style={hrStyle}/>

            <h1 id="cloudpbx">CloudPBX</h1>

            <div>
                <p>AsteriskやFreeSwitchを利用したカスタマイズ可能なPBXを構築します。</p>

                <hr style={hrStyle}/>

                <StaticImage
                    src="../images/cloudpbx/CloudPBX2.png"
                    alt={"CloudPBX"}
                />
            </div>

            <hr style={hrStyle}/>

            <div>
                <h4>システム構成</h4>
                <table>
                    <colgroup>
                        <col width="30%"/>
                        <col width="70%"/>
                    </colgroup>
                    <tr>
                        <td style={leftTdStyle}>PBX本体</td>
                        <td>■ 広く利用されている“Asterisk” や
                            安定して稼働する"FreeSwitch"等に対応可能です。
                            構築から、保守運用までサポートしています。<br/>
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>電話機/ソフトフォン</td>
                        <td>■ Windows/Mac, Android/iOSで動作可能なソフトフォンが利用できます。<br/>
                            固定電話機も利用可能です。 プロビジョニング機能対応機器であれば、個別に電話機を設定する手間を省く事ができます。
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>Web</td>
                        <td>■ 各種Web Frameworkに対応可能です。
                            また、CRMとの連携も行っています。
                        </td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>データベース</td>
                        <td>■ MySQL, Postgress, Microsoft SQL Serverなどをサポートしています</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>電話回線/Gateway</td>
                        <td>■ 各種キャリア・ゲートウェイに接続可能です。詳細はお問合せください。</td>
                    </tr>
                    <tr>
                        <td style={leftTdStyle}>Infrastructure</td>
                        <td>■ Azure, AWS, さくらのクラウド, Vultr, オンプレミスなどで実績があります。</td>
                    </tr>
                </table>
            </div>
            <hr style={hrStyle}/>

            <ContactUs />
        </div>
    </Layout>
)

export default CloudPBXPage
